import React, {Fragment} from "react";


function Notification() {
    return (
        <Fragment>
            <div>
                <p>Paiement Effectué avec Succès</p>
            </div>
        </Fragment>
    )
}

export default Notification;