import React, {Fragment} from 'react'

function DashBordHeader() {
    return (
        <Fragment>
            <div id="preloader-active">
              <div className="preloader d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                  <div className="text-center"><img src="assets/imgs/template/loading.gif" alt="jobBox" /></div>
                </div>
              </div>
            </div>
            <header className="header sticky-bar">
              <div className="container">
                <div className="main-header">
                  <div className="header-left">
                    <div className="header-logo"><a className="d-flex" href="index.html"><img alt="jobBox" src="assets/imgs/page/dashboard/logo.svg" /></a></div><span className="btn btn-grey-small ml-10">Admin area</span>
                  </div>
                  <div className="header-search">
                    <div className="box-search">
                      <form action="">
                        <input className="form-control input-search" type="text" name="keyword" placeholder="Search" />
                      </form>
                    </div>
                  </div>
                  <div className="header-menu d-none d-md-block">
                    <ul>
                      <li> <a href="http://wp.alithemes.com/html/jobbox/demos/index.html">Home </a></li>
                      <li> <a href="http://wp.alithemes.com/html/jobbox/demos/page-about.html">About us </a></li>
                      <li> <a href="http://wp.alithemes.com/html/jobbox/demos/page-contact.html">Contact</a></li>
                    </ul>
                  </div>
                  <div className="header-right">
                    <div className="block-signin"><a className="btn btn-default icon-edit hover-up" href="post-job.html">Post Job</a>
                      <div className="dropdown d-inline-block"><a className="btn btn-notify" id="dropdownNotify" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"></a>
                        <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end" aria-labelledby="dropdownNotify">
                          <li><a className="dropdown-item active" href="#">10 notifications</a></li>
                          <li><a className="dropdown-item" href="#">12 messages</a></li>
                          <li><a className="dropdown-item" href="#">20 replies</a></li>
                        </ul>
                      </div>
                      <div className="member-login"><img alt="" src="assets/imgs/page/dashboard/profile.png" />
                        <div className="info-member"> <strong className="color-brand-1">Steven Jobs</strong>
                          <div className="dropdown"><a className="font-xs color-text-paragraph-2 icon-down" id="dropdownProfile" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">Super Admin</a>
                            <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end" aria-labelledby="dropdownProfile">
                              <li><a className="dropdown-item" href="profile.html">Profiles</a></li>
                              <li><a className="dropdown-item" href="my-resume.html">CV Manager</a></li>
                              <li><a className="dropdown-item" href="login.html">Logout</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="burger-icon burger-icon-white"><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
            <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                  <div className="perfect-scroll">
                    <div className="mobile-search mobile-header-border mb-30">
                      <form action="#">
                        <input type="text" placeholder="Search…" /><i className="fi-rr-search"></i>
                      </form>
                    </div>
                    <div className="mobile-menu-wrap mobile-header-border">
                      <nav>
                        <ul className="main-menu">
                          <li> <a className="dashboard2 active" href="index.html"><img src="assets/imgs/page/dashboard/dashboard.svg" alt="jobBox" /><span className="name">Dashboard</span></a>
                          </li>
                          <li> <a className="dashboard2" href="candidates.html"><img src="assets/imgs/page/dashboard/candidates.svg" alt="jobBox" /><span className="name">Candidates</span></a>
                          </li>
                          <li> <a className="dashboard2" href="recruiters.html"><img src="assets/imgs/page/dashboard/recruiters.svg" alt="jobBox" /><span className="name">Recruiters</span></a>
                          </li>
                          <li> <a className="dashboard2" href="my-job-grid.html"><img src="assets/imgs/page/dashboard/jobs.svg" alt="jobBox" /><span className="name">My Jobs</span></a>
                          </li>
                          <li> <a className="dashboard2" href="my-tasks-list.html"><img src="assets/imgs/page/dashboard/tasks.svg" alt="jobBox" /><span className="name">Tasks List</span></a>
                          </li>
                          <li> <a className="dashboard2" href="profile.html"><img src="assets/imgs/page/dashboard/profiles.svg" alt="jobBox" /><span className="name">My Profiles</span></a>
                          </li>
                          <li> <a className="dashboard2" href="my-resume.html"><img src="assets/imgs/page/dashboard/cv-manage.svg" alt="jobBox" /><span className="name">CV Manage</span></a>
                          </li>
                          <li> <a className="dashboard2" href="settings.html"><img src="assets/imgs/page/dashboard/settings.svg" alt="jobBox" /><span className="name">Setting</span></a>
                          </li>
                          <li> <a className="dashboard2" href="authentication.html"><img src="assets/imgs/page/dashboard/authentication.svg" alt="jobBox" /><span className="name">Authentication</span></a>
                          </li>
                          <li> <a className="dashboard2" href="login.html"><img src="assets/imgs/page/dashboard/logout.svg" alt="jobBox" /><span className="name">Logout</span></a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Work Preferences</a></li>
                        <li><a href="#">Account Settings</a></li>
                        <li><a href="#">Go Pro</a></li>
                        <li><a href="page-signin.html">Sign Out</a></li>
                      </ul>
                      <div className="mb-15 mt-15"> <a className="btn btn-default icon-edit hover-up" href="post-job.html">Post Job</a></div>
                    </div>
                    <div className="site-copyright">Copyright 2022 &copy; JobBox. <br />Designed by AliThemes.</div>
                  </div>
                </div>
              </div>
            </div>
        </Fragment>
    )
}

export default DashBordHeader;