import React, {Fragment} from "react";

function Navbar() {
    return (
        <Fragment>
            <div id="preloader-active">
              <div className="preloader d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                  <div className="text-center"><img src="assets/imgs/template/loading.gif" alt="jobBox" /></div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ModalApplyJobForm" tabIndex="-1" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content apply-job-form">
                  <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  <div className="modal-body pl-30 pr-30 pt-50">
                    <div className="text-center">
                      <p className="font-sm text-brand-2">Job Application </p>
                      <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2>
                      <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.</p>
                    </div>
                    <form className="login-register text-start mt-20 pb-30" action="#">
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-1">Full Name *</label>
                        <input className="form-control" id="input-1" type="text" required="" name="fullname" placeholder="Steven Job" />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-2">Email *</label>
                        <input className="form-control" id="input-2" type="email" required="" name="emailaddress" placeholder="stevenjob@gmail.com" />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="number">Contact Number *</label>
                        <input className="form-control" id="number" type="text" required="" name="phone" placeholder="(+01) 234 567 89" />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="des">Description</label>
                        <input className="form-control" id="des" type="text" required="" name="Description" placeholder="Your description..." />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="file">Upload Resume</label>
                        <input className="form-control" id="file" name="resume" type="file" />
                      </div>
                      <div className="login_footer form-group d-flex justify-content-between">
                        <label className="cb-container">
                          <input type="checkbox" /><span className="text-small">Agree our terms and policy</span><span className="checkmark"></span>
                        </label><a className="text-muted" href="page-contact.html">Lean more</a>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-default hover-up w-100" type="submit" name="login">Apply Job</button>
                      </div>
                      <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <header className="header sticky-bar">
      <div className="container">
        <div className="main-header">
          <div className="header-left">
            <div className="header-logo"><a className="d-flex" href="index.html"><img alt="jobBox" src="assets/imgs/template/jobhub-logo.svg" /></a></div>
          </div>
          <div className="header-nav">
            <nav className="nav-main-menu">
              <ul className="main-menu">
                <li className="has-children"><a className="active" href="index.html">Home</a>
                  <ul className="sub-menu">
                    <li><a href="index.html">Home 1</a></li>
                    <li><a href="index-2.html">Home 2</a></li>
                    <li><a href="index-3.html">Home 3</a></li>
                    <li><a href="index-4.html">Home 4</a></li>
                    <li><a href="index-5.html">Home 5</a></li>
                    <li><a href="index-6.html">Home 6</a></li>
                  </ul>
                </li>
                <li className="has-children"><a href="jobs-grid.html">Find a Job</a>
                  <ul className="sub-menu">
                    <li><a href="jobs-grid.html">Jobs Grid</a></li>
                    <li><a href="jobs-list.html">Jobs List</a></li>
                    <li><a href="job-details.html">Jobs Details  </a></li>
                    <li><a href="job-details-2.html">Jobs Details 2</a></li>
                  </ul>
                </li>
                <li className="has-children"><a href="companies-grid.html">Recruiters</a>
                  <ul className="sub-menu">
                    <li><a href="companies-grid.html">Recruiters</a></li>
                    <li><a href="company-details.html">Company Details</a></li>
                  </ul>
                </li>
                <li className="has-children"><a href="candidates-grid.html">Candidates</a>
                  <ul className="sub-menu">
                    <li><a href="candidates-grid.html">Candidates Grid</a></li>
                    <li><a href="candidate-details.html">Candidate Details</a></li>
                    <li><a href="candidate-profile.html">Candidate Profile</a></li>
                  </ul>
                </li>
                <li className="has-children"><a href="blog-grid.html">Pages</a>
                  <ul className="sub-menu">
                    <li><a href="page-about.html">About Us</a></li>
                    <li><a href="page-pricing.html">Pricing Plan</a></li>
                    <li><a href="page-contact.html">Contact Us</a></li>
                    <li><a href="page-register.html">Register</a></li>
                    <li><a href="page-signin.html">Signin</a></li>
                    <li><a href="page-reset-password.html">Reset Password</a></li>
                    <li><a href="page-content-protected.html">Content Protected</a></li>
                  </ul>
                </li>
                <li className="has-children"><a href="blog-grid.html">Blog</a>
                  <ul className="sub-menu">
                    <li><a href="blog-grid.html">Blog Grid</a></li>
                    <li><a href="blog-grid-2.html">Blog Grid 2</a></li>
                    <li><a href="blog-details.html">Blog Single</a></li>
                  </ul>
                </li>
                <li className="dashboard"><a href="http://wp.alithemes.com/html/jobbox/demos/dashboard" target="_blank">Dashboard</a></li>
              </ul>
            </nav>
            <div className="burger-icon burger-icon-white"><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
          </div>
          <div className="header-right">
            <div className="block-signin"><a className="text-link-bd-btom hover-up" href="page-register.html">Register</a><a className="btn btn-default btn-shadow ml-40 hover-up" href="page-signin.html">Sign in</a></div>
          </div>
        </div>
      </div>
    </header>
            <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                  <div className="perfect-scroll">
                    <div className="mobile-search mobile-header-border mb-30">
                      <form action="#">
                        <input type="text" placeholder="Search…" /><i className="fi-rr-search"></i>
                      </form>
                    </div>
                    <div className="mobile-menu-wrap mobile-header-border">
                      <nav>
                        <ul className="mobile-menu font-heading">
                          <li className="has-children"><a className="active" href="index.html">Home</a>
                            <ul className="sub-menu">
                              <li><a href="index.html">Home 1</a></li>
                              <li><a href="index-2.html">Home 2</a></li>
                              <li><a href="index-3.html">Home 3</a></li>
                              <li><a href="index-4.html">Home 4</a></li>
                              <li><a href="index-5.html">Home 5</a></li>
                              <li><a href="index-6.html">Home 6</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="jobs-grid.html">Find a Job</a>
                            <ul className="sub-menu">
                              <li><a href="jobs-grid.html">Jobs Grid</a></li>
                              <li><a href="jobs-list.html">Jobs List</a></li>
                              <li><a href="job-details.html">Jobs Details  </a></li>
                              <li><a href="job-details-2.html">Jobs Details 2              </a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="companies-grid.html">Recruiters</a>
                            <ul className="sub-menu">
                              <li><a href="companies-grid.html">Recruiters</a></li>
                              <li><a href="company-details.html">Company Details</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="candidates-grid.html">Candidates</a>
                            <ul className="sub-menu">
                              <li><a href="candidates-grid.html">Candidates Grid</a></li>
                              <li><a href="candidate-details.html">Candidate Details</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="blog-grid.html">Pages</a>
                            <ul className="sub-menu">
                              <li><a href="page-about.html">About Us</a></li>
                              <li><a href="page-pricing.html">Pricing Plan</a></li>
                              <li><a href="page-contact.html">Contact Us</a></li>
                              <li><a href="page-register.html">Register</a></li>
                              <li><a href="page-signin.html">Signin</a></li>
                              <li><a href="page-reset-password.html">Reset Password</a></li>
                              <li><a href="page-content-protected.html">Content Protected</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="blog-grid.html">Blog</a>
                            <ul className="sub-menu">
                              <li><a href="blog-grid.html">Blog Grid</a></li>
                              <li><a href="blog-grid-2.html">Blog Grid 2</a></li>
                              <li><a href="blog-details.html">Blog Single</a></li>
                            </ul>
                          </li>
                          <li><a href="http://wp.alithemes.com/html/jobbox/demos/dashboard" target="_blank">Dashboard</a></li>
                        </ul>
                      </nav>
                    </div>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Work Preferences</a></li>
                        <li><a href="#">Account Settings</a></li>
                        <li><a href="#">Go Pro</a></li>
                        <li><a href="page-signin.html">Sign Out</a></li>
                      </ul>
                    </div>
                    <div className="site-copyright">Copyright 2022 &copy; JobBox. <br />Designed by AliThemes.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                  <div className="perfect-scroll">
                    <div className="mobile-search mobile-header-border mb-30">
                      <form action="#">
                        <input type="text" placeholder="Search…" /><i className="fi-rr-search"></i>
                      </form>
                    </div>
                    <div className="mobile-menu-wrap mobile-header-border">
                      <nav>
                        <ul className="mobile-menu font-heading">
                          <li className="has-children"><a className="active" href="index.html">Home</a>
                            <ul className="sub-menu">
                              <li><a href="index.html">Home 1</a></li>
                              <li><a href="index-2.html">Home 2</a></li>
                              <li><a href="index-3.html">Home 3</a></li>
                              <li><a href="index-4.html">Home 4</a></li>
                              <li><a href="index-5.html">Home 5</a></li>
                              <li><a href="index-6.html">Home 6</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="jobs-grid.html">Find a Job</a>
                            <ul className="sub-menu">
                              <li><a href="jobs-grid.html">Jobs Grid</a></li>
                              <li><a href="jobs-list.html">Jobs List</a></li>
                              <li><a href="job-details.html">Jobs Details  </a></li>
                              <li><a href="job-details-2.html">Jobs Details 2              </a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="companies-grid.html">Recruiters</a>
                            <ul className="sub-menu">
                              <li><a href="companies-grid.html">Recruiters</a></li>
                              <li><a href="company-details.html">Company Details</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="candidates-grid.html">Candidates</a>
                            <ul className="sub-menu">
                              <li><a href="candidates-grid.html">Candidates Grid</a></li>
                              <li><a href="candidate-details.html">Candidate Details</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="blog-grid.html">Pages</a>
                            <ul className="sub-menu">
                              <li><a href="page-about.html">About Us</a></li>
                              <li><a href="page-pricing.html">Pricing Plan</a></li>
                              <li><a href="page-contact.html">Contact Us</a></li>
                              <li><a href="page-register.html">Register</a></li>
                              <li><a href="page-signin.html">Signin</a></li>
                              <li><a href="page-reset-password.html">Reset Password</a></li>
                              <li><a href="page-content-protected.html">Content Protected</a></li>
                            </ul>
                          </li>
                          <li className="has-children"><a href="blog-grid.html">Blog</a>
                            <ul className="sub-menu">
                              <li><a href="blog-grid.html">Blog Grid</a></li>
                              <li><a href="blog-grid-2.html">Blog Grid 2</a></li>
                              <li><a href="blog-details.html">Blog Single</a></li>
                            </ul>
                          </li>
                          <li><a href="http://wp.alithemes.com/html/jobbox/demos/dashboard" target="_blank">Dashboard</a></li>
                        </ul>
                      </nav>
                    </div>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Work Preferences</a></li>
                        <li><a href="#">Account Settings</a></li>
                        <li><a href="#">Go Pro</a></li>
                        <li><a href="page-signin.html">Sign Out</a></li>
                      </ul>
                    </div>
                    <div className="site-copyright">Copyright 2022 &copy; JobBox. <br />Designed by AliThemes.</div>
                  </div>
                </div>
              </div>
            </div>
        </Fragment>
    )
}

export default Navbar;