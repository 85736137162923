import React, {Fragment} from "react";
//import LogoImg from "../assets/imgs/logos/logobleu.png";
import LogoImg from "../assets/imgs/logos/Logo_Yaako.png";

function Footer() {
    return (
        <Fragment>
            <footer className="footer mt-50">
              <div className="container">
                <div className="row">
                  <div className="footer-col-1 col-md-6 col-sm-12"><a href="/"><img alt="jobBox" src={LogoImg} style={{width: "450px", height: "150px", borderRadius: "25px"}} /></a>
                    <div className="mt-20 mb-20 font-xs color-text-paragraph-2"></div>
                    <div className="footer-social text-center" style={{marginTop: "-10px"}}>
                      <a className="icon-socials icon-facebook" href="#"></a>
                      <a className="icon-socials icon-twitter" href="#"></a>
                      <a className="icon-socials icon-linkedin" href="#"></a>
                      {/*<a className="icon-socials icon-linkedin" href="#"></a>*/}
                    </div>
                  </div>
                  {/*<div className="col-md-3 col-xs-6">*/}
                  {/*  <h6 className="mb-20">Resources</h6>*/}
                  {/*  <ul className="menu-footer" style={{marginTop: "-10px"}}>*/}
                  {/*    <li><a href="#">About us</a></li>*/}
                  {/*    <li><a href="#">Our Team</a></li>*/}
                  {/*    <li><a href="#">Products</a></li>*/}
                  {/*    <li><a href="#">Contact</a></li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                  <div className="col-md-3 col-sm-12">
                    <h6 className="mb-20">Download App</h6>
                    <p className="color-text-paragraph-2 font-xs">Download our Apps and get extra 15% Discount on your first Order&mldr;!</p>
                    <div className="mt-15">
                      <a className="mr-5" href="#"><img src="assets/imgs/template/icons/app-store.png" alt="joxBox" /></a>
                      <a href="#"><img src="assets/imgs/template/icons/android.png" alt="joxBox" /></a>
                    </div>
                  </div>
                </div>
                <div className="footer-bottom mt-30">
                  <div className="row">
                    <div className="col-md-6"><span className="font-xs color-text-paragraph">Copyright &copy; 2022. Koliac ensemble avec vous Tous droits réservés</span></div>
                    <div className="col-md-6 text-md-end text-start">
                      <div className="footer-social">
                        <a className="font-xs color-text-paragraph mr-30 ml-30" href="#">Termes &amp; Conditions Utilisateurs</a>
                        <a className="font-xs color-text-paragraph" href="#">Sécurité</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
        </Fragment>
    )
}

export default Footer;