import React, {useContext, useEffect, useState, Fragment} from "react";
// import 'bootstrap/dist/css/bootstrap.css';
import Header from "./_partials/Header";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Loader from "./assets/imgs/template/loading.gif";
import Pagination from "./Pagination";

import BaseUrl from "./config/baseUrl";

const url = BaseUrl();

function Annonces() {
    const [annonces, setAnnonces] = useState([]);
    const [annoncesdeces, setAnnoncesDeces] = useState([]);
    const [annoncesmemoriam, setAnnoncesInMemoriam] = useState([]);
    const [dataIsloading, setDataIsLoading] = useState(true)

    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [annoncesPerPage, setannoncesPerPage] = useState(12);

    const lastAnnonceIndex = currentPage * annoncesPerPage;
    const firstAnnonceindex = lastAnnonceIndex - annoncesPerPage;
    const currentAnnonces = annonces.slice(firstAnnonceindex, lastAnnonceIndex);


    const navigate = useNavigate();

    //from data from backend with axios using UseEffect
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetAnnonces() {
            try{
                const response = await axios
                    .get(
                        url+'/annonces/',
                         { cancelToken: source.token }
                    );
                setAnnonces(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetAnnonces();
        return () => {
            source.cancel();
        };
    }, []);

         //Get Last Annonces Deces
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetAnnoncesDeces() {
            try{
                const response = await axios
                    .get(
                        url+'/annonceDeces/',
                         { cancelToken: source.token }
                    );
                setAnnoncesDeces(response.data);
                // console.log(response.data)
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetAnnoncesDeces();
        return () => {
            source.cancel();
        };
    }, []);

   //Get Last Annonces In Memoriam
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetAnnoncesInMemoriam() {
            try{
                const response = await axios
                    .get(
                        url+'/annonceInmemoriam/',
                         { cancelToken: source.token }
                    );
                setAnnoncesInMemoriam(response.data);
                // console.log(response.data)
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetAnnoncesInMemoriam();
        return () => {
            source.cancel();
        };
    }, []);

    if(dataIsloading === false) {
        // console.log(annonces);
    }

    if (dataIsloading === true) {
      return (

      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center"><img src={Loader} alt="jobBox" /></div>
          </div>
        </div>
      </div>

      );
    }
    return (
        <Fragment>
            <Header />
            <main className="main">
              <section className="section-box-2">
                <div className="container">
                  <div className="banner-hero banner-single banner-single-bg">
                    <div className="block-banner text-center">
                      <h3 className="wow animate__animated animate__fadeInUp"><span className="color-brand-2">{annonces.length} Annonces Disponibles</span></h3>
                      {/*<div className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero repellendus magni, <br className="d-none d-xl-block" />atque delectus molestias quis?</div>*/}
                      <div className="form-find text-start mt-40 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                        <form>
                          <input className="form-input input-keysearch mr-10" type="text"
                                 onChange={(e) => setSearch(e.target.value)}
                                 placeholder="Rechercher une annonce par Code ou par le Titre........ "
                          />
                          <button className="btn btn-default btn-find font-sm">Rechercher</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section-box mt-20">
                <div className="container">
                  <div className="row flex-row-reverse">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="content-page">
                        <div className="box-filters-job">
                          <div className="row">
                              <div className="list-tabs col-xl-12 col-lg-12 text-lg-center mt-sm-15 text-center">
                                  <ul className="nav nav-tabs center" role="tablist" style={{textAlign: "center"}}>
                                    <li><a className="active" id="nav-tab-job-1" href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                                        <img src="assets/imgs/page/homepage1/management.svg" alt="jobBox" /> Tous  <span className="badge bg-info"> {annonces.length}</span></a>
                                    </li>
                                    <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                                        <img src="assets/imgs/page/homepage1/human.svg" alt="jobBox" /> DECES  <span className="badge bg-danger"> {annoncesdeces.length}</span></a>
                                    </li>
                                    <li><a id="nav-tab-job-3" href="#tab-job-3" data-bs-toggle="tab" role="tab" aria-controls="tab-job-3" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                                        <img src="assets/imgs/page/homepage1/content.svg" alt="jobBox" /> In Mémoriam  <span className="badge bg-primary"> {annoncesmemoriam.length}</span></a>
                                    </li>
                                  </ul>
                             </div>
                          </div>
                        </div>
                        <div className="mt-50">
                            <div className="tab-content" id="myTabContent-1">
                                <div className="tab-pane fade show active" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                                <div className="row">
                                  {currentAnnonces.filter((annonce) => {
                                      return search.toLowerCase() === ""
                                          ? annonce
                                          : annonce.code.toLowerCase().includes(search) || annonce.titre.toLowerCase().includes(search);
                                  }).map((annonce, index) =>(
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                        {/*<div className="col-lg-2 col-md-6" key={annonce.id}>*/}
                                        <div className="card-grid-2 grid-bd-16 hover-up">
                                            <div className="card-grid-2-image">
                                                {annonce.categorie === "DECES" ?
                                                    <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                                    <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                                }
                                            <div className="image-box">
                                                {annonce.image1 ?
                                                    <figure>
                                                          <img
                                                              src={annonce.image1}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure> :
                                                    <figure>
                                                          <img
                                                              src={annonce.qr_code}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure>
                                                }
                                            </div>
                                          </div>
                                            <div className="card-block-info">
                                                {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                                <h5>
                                                    <Link to={`/annonces/${annonce.id}`}>
                                                        {annonce.titre}
                                                    </Link>
                                                </h5>
                                                <p className="mt-10 color-text-paragraph font-sm">
                                                  {annonce.programme.substring(0,100)} ...
                                                </p>
                                                <div className="card-2-bottom mt-20">
                                                  <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                      <div className="d-flex">
                                                        {annonce.user.image ?
                                                            <img className="img-rounded" src={annonce.user.image} /> :
                                                            <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                        }
                                                        <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                        Date : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                                <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2">
                                <div className="row">
                                  {annoncesdeces.filter((annonce) => {
                                      return search.toLowerCase() === ""
                                          ? annonce
                                          : annonce.code.toLowerCase().includes(search) || annonce.titre.toLowerCase().includes(search);
                                  }).map((annonce, index) =>(
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                        <div className="card-grid-2 grid-bd-16 hover-up">
                                            <div className="card-grid-2-image">
                                                {annonce.categorie === "DECES" ?
                                                    <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                                    <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                                }
                                            <div className="image-box">
                                                {annonce.image1 ?
                                                    <figure>
                                                          <img
                                                              src={annonce.image1}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure> :
                                                    <figure>
                                                          <img
                                                              src={annonce.qr_code}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure>
                                                }
                                            </div>
                                          </div>
                                            <div className="card-block-info">
                                                {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                                <h5>
                                                    <Link to={`/annonces/${annonce.id}`}>
                                                        {annonce.titre}
                                                    </Link>
                                                </h5>
                                                <p className="mt-10 color-text-paragraph font-sm">
                                                  {annonce.programme.substring(0,100)} ...
                                                </p>
                                                <div className="card-2-bottom mt-20">
                                                  <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                      <div className="d-flex">
                                                        {annonce.user.image ?
                                                            <img className="img-rounded" src={annonce.user.image} /> :
                                                            <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                        }
                                                        <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                        Date : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                                <div className="tab-pane fade" id="tab-job-3" role="tabpanel" aria-labelledby="tab-job-3">
                                <div className="row">
                                  {annoncesmemoriam.filter((annonce) => {
                                      return search.toLowerCase() === ""
                                              ? annonce
                                              : annonce.code.toLowerCase().includes(search) || annonce.titre.toLowerCase().includes(search);
                                      }).map((annonce, index) =>(
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                        <div className="card-grid-2 grid-bd-16 hover-up">
                                            <div className="card-grid-2-image">
                                                {annonce.categorie === "DECES" ?
                                                    <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                                    <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                                }
                                            <div className="image-box">
                                                {annonce.image1 ?
                                                    <figure>
                                                          <img
                                                              src={annonce.image1}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure> :
                                                    <figure>
                                                          <img
                                                              src={annonce.qr_code}
                                                              width={350}
                                                              height={300}
                                                              style={{cursor: "pointer"}}
                                                              // style={{width: "100%", height: "250px"}}
                                                              alt={annonce.titre}
                                                              onClick={() =>navigate(
                                                                  `/annonces/${annonce.id}`
                                                              )}
                                                          />
                                                    </figure>
                                                }
                                            </div>
                                          </div>
                                            <div className="card-block-info">
                                                {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                                <h5>
                                                    <Link to={`/annonces/${annonce.id}`}>
                                                        {annonce.titre}
                                                    </Link>
                                                </h5>
                                                <p className="mt-10 color-text-paragraph font-sm">
                                                  {annonce.programme.substring(0,100)} ...
                                                </p>
                                                <div className="card-2-bottom mt-20">
                                                  <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                      <div className="d-flex">
                                                        {annonce.user.image ?
                                                            <img className="img-rounded" src={annonce.user.image} /> :
                                                            <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                        }
                                                        <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                        Date : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                            </div>
                            </div>
                        </div>
                      </div>
                      <Pagination
                            totalAnnonces={annonces.length}
                            annoncesPerPage={annoncesPerPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                      />
                      {/*<div className="paginations">*/}
                      {/*  <ul className="pager">*/}
                      {/*    <li><a className="pager-prev" href="#"></a></li>*/}
                      {/*    <li><a className="pager-number" href="#">1</a></li>*/}
                      {/*    <li><a className="pager-number" href="#">2</a></li>*/}
                      {/*    <li><a className="pager-number" href="#">3</a></li>*/}
                      {/*    <li><a className="pager-number" href="#">4</a></li>*/}
                      {/*    <li><a className="pager-number" href="#">5</a></li>*/}
                      {/*    <li><a className="pager-number active" href="#">6</a></li>*/}
                      {/*    <li><a className="pager-number" href="#">7</a></li>*/}
                      {/*    <li><a className="pager-next" href="#"></a></li>*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </section>
            </main>
        </Fragment>
    )

}

export default Annonces;