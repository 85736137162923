import React, {useState, useEffect, Fragment, useContext} from "react";
import axios from "axios";
import Stepper from 'react-stepper-horizontal';
// React Leaflet Component
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import { Icon } from 'leaflet';
import {useImmerReducer} from "use-immer";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./_partials/Header";
import Loader from "./assets/imgs/template/loading.gif";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import EgliseIconPng from './assets/Mapicons/cathedral.png';
import CimetierePng from './assets/Mapicons/catholicgrave.png'
import HomeIconPng from './assets/Mapicons/home-2.png'
import annonces from "./Annonces";
import moment from "moment";

import BaseUrl from "./config/baseUrl";
import { API_KEY, SITE_ID, NOTIFY_URL, MODE, CURRENCY, CHANNELS } from './config/config';
import DispatchContext from "../Context/DispatchContext";
import Swal from "sweetalert2";
import StateContext from "../Context/StateContext";

const url = BaseUrl()

function DetailAnnonce() {
    //Create State to Store the data from Server
    const GlobalState = useContext(StateContext)
    const navigate = useNavigate();
    const [allpoints, setAllpoints] = useState([])
    const [allmessages, setAllMessages] = useState([])
    const params = useParams()
    const [dataIsloading, setDataIsLoading] = useState(true)
    const [open, setOpen] = React.useState(false)
    const shareUrl = url+`/annonces/${params.id}/`
    // const shareUrl = `baseUrl+"/annonces/annonces/"${params.id}/`

    const [messageData,setmessageData] = useState({
        'user': "",
        'annonce': "",
        'contenu': "",
    });

    const handleAddMessage=(event)=>{
      setmessageData({
        ...messageData,
        [event.target.name]:event.target.value
      });
    }

    const onPenModaMessage=()=>{
        {
            window.$('#ModalDonMessageForm').modal('show')
        }

    }

    const submitMessage=()=>{
          // setErrorM(Validation(producteurData));
          if( messageData.contenu !="" )
          {
            const _formData = new FormData();
            _formData.append('user', GlobalState.userId);
            _formData.append('annonce', state.annonceInfo.id);
            _formData.append('contenu', messageData.contenu);

            //console.log(_formData);

            //  Swal.fire({
            //   title: 'Enregistrement...',
            //   html: 'Veillez patientez...',
            //   allowEscapeKey: false,
            //   allowOutsideClick: false,
            //   didOpen: () => {
            //     Swal.showLoading()
            //   },
            // });
            try {
              axios.post(url+'/messages/add/',_formData).then((resp)=>{
                Swal.close()
                if(resp.data.bool)
                // console.log(resp.data)
                {
                  window.$('#ModalDonMessageForm').modal('hide');
                  Swal.fire({
                    title: 'FELICITATION !',
                    html: " Message Ajouter avec Succès ",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  })
                }
              })
            } catch (error) {
              console.log(error);
            }
          }
        }

    const EgliseIcon = new Icon({
        iconUrl: EgliseIconPng,
        iconSize: [40, 40]
    });

    const DomicileIcon = new Icon({
        iconUrl: HomeIconPng,
        iconSize: [40, 40]
    });

    const CimetiereIcon = new Icon({
        iconUrl: CimetierePng,
        iconSize: [40, 40]
    });

    const initialState = {
        mapInstance : null,
        annonceInfo: "",
        userInfo: "",
        annoncePoints : [],
        annonceMessages: [],
    }

    function ReducerFunction (draft, action) {
          switch(action.type) {
            case "catchUserInfo":
                draft.userInfo = action.userObjet
            break;
            case "catchAnnonceInfos":
                draft.annonceInfo = action.annonceObject;
            break;
            case "catchAnnoncePoints":
                draft.annoncePoints = action.emplacement_messages;
            break;
            case "catchAnnonceMessages":
                draft.annonceMessages = action.annonce_messages;
            break;
            case "getMap":
                draft.mapInstance = action.mapData;
            break;

          }
      }

    function TheMapComponent() {
      const map =useMap()
      dispatch({
          type: "getMap",
          mapData: map
      });
      return null;
    }

    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

    //get Profile Infos | recuperer les Données du Profile de l'utilisateur a excécuter lors du Chargement de la page
    useEffect(()=> {
        if (state.pointInfo) {
            async function getUserInfos() {
            try {
                const response = await axios
                .get(url+`profiles/${state.pointInfo.auteur}/`);
                // console.log(response.data)
                dispatch({
                    type:"catchUserInfo",
                    profileObjet: response.data
                });

                // dispatch({type: 'loadingDone'})
            } catch (e) {
                console.log(e.response)
            }
        }
            getUserInfos()
        }
    }, [state.pointInfo])

    //get recuperer les Données de l'Annonce
    useEffect(()=> {
        async function getAnnonceInfos() {
            try {
                const response = await axios
                .get(url+`/annonces/${params.id}/`);
                // setAllpois(response.data.);
                setDataIsLoading(false)
                // console.log(response.data)
                dispatch({
                    type:"catchAnnonceInfos",
                    //on charge les Infos dans la Variable annonceObject
                    annonceObject: response.data,
                });
                dispatch({type: 'loadingDone'})
            } catch (e) {
                console.log(e.response)
            }
        }
        getAnnonceInfos()
    }, [])

    const pointPicteures = [
        state.annonceInfo.image1,
        state.annonceInfo.image2,
        state.annonceInfo.image3,
        state.annonceInfo.image4,
        state.annonceInfo.image5,
    ].filter((image)=> image !== null);

    const [currentImage, setCurrentImage] = useState(0)

    function NextImage() {
        if (currentImage === pointPicteures.length -1) {
            return setCurrentImage(0)
        } else {
            setCurrentImage(currentImage + 1)
        }
    }

    function PreviousImage() {
        if(currentImage === 0) {
            return setCurrentImage(pointPicteures.length -1)
        } else {
            setCurrentImage(currentImage - 1)
        }
    }

    // //from data from backend with axios using UseEffect
    // useEffect(() => {
    //     const source = axios.CancelToken.source();
    //     async function GetPoints() {
    //         try{
    //             const response = await axios
    //                 .get(
    //                     'http://127.0.0.1:8000/api/emplacements/',
    //                      { cancelToken: source.token }
    //                 );
    //             setAllpoints(response.data);
    //             console.log(response);
    //             setDataIsLoading(false)
    //         } catch (error) {
    //             console.log(error.response);
    //         }
    //     }
    //     GetPoints();
    //     return () => {
    //         source.cancel();
    //     };
    // }, []);
    //
    // //from data from backend with axios using UseEffect
    // useEffect(() => {
    //     const source = axios.CancelToken.source();
    //     async function GetMessages() {
    //         try{
    //             const response = await axios
    //                 .get(
    //                     'http://127.0.0.1:8000/api/messages/',
    //                      { cancelToken: source.token }
    //                 );
    //             setAllMessages(response.data);
    //             console.log(response.data);
    //             setDataIsLoading(false)
    //         } catch (error) {
    //             console.log(error.response);
    //         }
    //     }
    //     GetMessages();
    //     return () => {
    //         source.cancel();
    //     };
    // }, []);

    const date = new Date(state.annonceInfo.add_le)
    const FormattedDate = `${date.getDate() + 1}/${date.getMonth()}/${date.getFullYear()}`

    if (dataIsloading === true) {
      return (

      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center"><img src={Loader} alt="jobBox" /></div>
          </div>
        </div>
      </div>
      );
    }

    // function checkout () {
    //     let CinetPay="";
    //     CinetPay.setConfig({
    //          apikey: '122849245365f9bc10243b46.95821228',//   YOUR APIKEY
    //         site_id: '5866540',//YOUR_SITE_ID
    //         notify_url: 'http://5.196.67.164:3000/notify/',
    //         mode: 'PRODUCTION'
    //     });
    //     CinetPay.getCheckout({
    //         transaction_id: Math.floor(Math.random() * 100000000).toString(),
    //         amount: 100,
    //         currency: 'XOF',
    //         channels: 'ALL',
    //         description: 'Test de paiement',
    //          //Fournir ces variables pour le paiements par carte bancaire
    //         // customer_name:"Joe",//Le nom du client
    //         // customer_surname:"Down",//Le prenom du client
    //         // customer_email: "down@test.com",//l'email du client
    //         // customer_phone_number: "088767611",//l'email du client
    //         // customer_address : "BP 0024",//addresse du client
    //         // customer_city: "Antananarivo",// La ville du client
    //         // customer_country : "CI",// le code ISO du pays
    //         // customer_state : "CI",// le code ISO l'état
    //         // customer_zip_code : "00225", // code postal
    //     });
    //     CinetPay.waitResponse(function(data) {
    //         if (data.status == "REFUSED") {
    //             if (alert("Votre paiement a échoué")) {
    //                 window.location.reload();
    //             }
    //         } else if (data.status == "ACCEPTED") {
    //             if (alert("Votre paiement a été effectué avec succès")) {
    //                 window.location.reload();
    //             }
    //         }
    //     });
    //     CinetPay.onError(function(data) {
    //         console.log(data);
    //     });
    // }

    function checkout() {

        let CinetPay= "";
        CinetPay.setConfig({
        apikey: API_KEY,
        site_id: SITE_ID,

        notify_url: NOTIFY_URL,

        mode: MODE,

      });


      const transactionId = Math.floor(Math.random() * 100000000).toString();

      const amount = 100;

      const customerInfo = {

        name: 'Joe',

        surname: 'Down',

        email: 'down@test.com',

        phoneNumber: '088767611',

        address: 'BP 0024',

        city: 'Antananarivo',

        country: 'CM',

        state: 'CM',

        zipCode: '06510',

      };


      // Validate customer info

      if (!validateCustomerInfo(customerInfo)) {

        console.error('Invalid customer information');

        return;

      }


      CinetPay.getCheckout({

        transaction_id: transactionId,

        amount,

        currency: CURRENCY,

        channels: CHANNELS,

        description: 'Test de paiement',

       ...customerInfo,

      });


      CinetPay.waitResponse((data) => {

        if (data.status === 'REFUSED') {

          displayError('Votre paiement a échoué');

        } else if (data.status === 'ACCEPTED') {

          displaySuccess('Votre paiement a été effectué avec succès');

        }

      });


      CinetPay.onError((data) => {

        console.error('Error:', data);

        displayError('Erreur lors du paiement');

      });

    }


function validateCustomerInfo(customerInfo) {

  // Implement validation logic here

  return true; // or false if validation fails

}


function displayError(message) {

  // Implement error display logic here

  console.error(message);

}


function displaySuccess(message) {

  // Implement success display logic here

  console.log(message);

}

    function DisplayButtonMessage() {
        if(GlobalState.userIsConnecter) {
        return (
            <div className="btn btn-apply-icon btn-apply btn-apply-big hover-up"
                 data-bs-toggle="modal" data-bs-target="#ModalDonMessageForm" style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "16px"}}>
                Laisser Un Message
            </div>
        )
        } else if (!GlobalState.userIsConnecter) {
            return (
                <div className="btn btn-apply-icon btn-apply btn-apply-big hover-up">
                    <Link to="/connexion" style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "16px"}}>
                        Laisser Un Message
                    </Link>
                </div>
            )
        }
    }
    function DisplayButtonContribuer() {
        if(GlobalState.userIsConnecter) {
        return (
                <div
                    role="link"
                    onClick={() => navigate('/contribution')}
                    className="btn btn-apply-icon btn-apply btn-apply-big hover-up" style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "16px"}}>
                    Contribuer
                </div>
        )
        } else if (!GlobalState.userIsConnecter) {
            return (
                <div className="btn btn-apply-icon btn-apply btn-apply-big hover-up">
                    <Link to="/connexion"
                          style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "16px"}}>
                        Contribuer
                    </Link>
                </div>
            )
        }
    }



    return (
        <Fragment>
            <Header/>
            <main className="main">
                <section className="section-box-2">
                    <div className="container">
                        {pointPicteures.length > 0 ? (
                            <div className="banner-hero banner-image-single col-lg-8 offset-2" style={{position: "relative", marginTop: "1px"}}>
                            {pointPicteures.map((image, index) => {
                                return (
                                    <div key={index}>
                                        {index === currentImage ? <img width={50} src={image} height={650} alt="jobBox" /> : "" }
                                        {/*{index === currentImage ? <img src={image} width={50} height={500} alt="jobBox" /> : "" }*/}
                                    </div>
                                )
                            })}
                            <ArrowCircleLeftIcon
                                onClick={PreviousImage}
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    fontSize: "3rem",
                                    color: "white",
                                    top: "45%",
                                    left: ".5%",
                                    "&:hover": {
                                        backgroundColor: "green"
                                    }
                                }}
                            />
                            <ArrowCircleRightIcon
                                onClick={NextImage}
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    fontSize: "3rem",
                                    color: "white",
                                    top: "45%",
                                    right: ".5%",
                                    "&:hover": {
                                        backgroundColor: "green"
                                    }
                                }}
                            />
                        </div>
                    ): ("")}
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <h3>{state.annonceInfo.titre.toUpperCase()}</h3>
                      <div className="mt-0 mb-15">
                          {state.annonceInfo.categorie === "DECES" ?
                              <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{state.annonceInfo.categorie}</span></span> :
                              <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{state.annonceInfo.categorie}</span></span>
                          }
                          <br/>
                          Survenu le : <span className="card-time" style={{fontWeight: "Bold", fontSize: "16px", color: 'black'}}> {state.annonceInfo.date}</span>
                      </div>
                    </div>
                      <div className="col-lg-4 col-md-12 text-lg-end">
                          {DisplayButtonContribuer()}
                    
                      </div>
                  </div>
                    <div className="border-bottom pt-10 pb-10"></div>
                </div>
              </section>
                <section className="section-box mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                {state.annonceInfo.faire_part ?
                                    <div className="job-overview">
                                        <h5 className="border-bottom pb-15 mb-30 text-justify lh-1">Faire part</h5>
                                        <p style={{whiteSpace: "pre-wrap"}}>
                                            {state.annonceInfo.faire_part}
                                        </p>
                                    </div> : ""
                                }

                                {state.annonceInfo.programme ?
                                    <div className="job-overview">
                                        <h5 className="border-bottom pb-15 mb-30 text-justify lh-lg">Programme</h5>
                                        <p style={{whiteSpace: "pre-wrap"}}>
                                            {state.annonceInfo.programme}
                                        </p>
                                    </div> : ""
                                }
                                <div className="single-apply-jobs">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            {DisplayButtonMessage()}
                                        </div>
                                        <div className="col-md-7 text-lg-end social-share">
                                            <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">Partager
                                                Sur : </h6>
                                            {/*<a className="mr-5 d-inline-block d-middle" href="#">*/}
                                            <EmailShareButton url={shareUrl} className="mr-5 d-inline-block d-middle">
                                                <EmailIcon size={40} round={true}/>
                                            </EmailShareButton>
                                            <FacebookShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <FacebookIcon size={40} round={true}/>
                                            </FacebookShareButton>
                                            <TwitterShareButton url={shareUrl} className="mr-5 d-inline-block d-middle">
                                                <TwitterIcon size={40} round={true}/>
                                            </TwitterShareButton>
                                            <LinkedinShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <LinkedinIcon size={40} round={true}/>
                                            </LinkedinShareButton>
                                            <WhatsappShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <WhatsappIcon size={40} round={true}/>
                                            </WhatsappShareButton>
                                            <TelegramShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <TelegramIcon size={40} round={true}/>
                                            </TelegramShareButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-single">
                                    <div className="row align-items-center">
                                        <div className="col-md-4" style={{}}>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                                <div className="sidebar-border">
                                    <h6 className="f-18">Messages</h6>
                                    <div className="sidebar-list-job">
                                        <ul>
                                            {state.annonceInfo.messages_annonce.map((message) => {
                                                return (
                                                    <li key={message.id}>
                                                        <div
                                                            className="card-list-4 wow animate__animated animate__fadeIn hover-up">
                                                            <div className="image"><a href="job-details.html">
                                                                <img src={message.user.image}
                                                                     style={{width: '25px', height: '25px'}}
                                                                     alt="jobBox"/></a>
                                                            </div>
                                                            <div className="info-text">
                                                                <h5 className="font-md font-bold color-brand-1"><a
                                                                    href="#">{message.user.nom}</a></h5>
                                                                <div className="mt-0">
                                                  <span className="card-time">
                                                      {/*<span>{moment(message.created_le).format('DD/MM/YYYY').fromNow(true)}</span>*/}
                                                      <span>{moment(message.created_le).fromNow(true)}</span>
                                                      {/*<span>{message.created_le}</span>*/}
                                                  </span>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <h6 className="card-price">
                                                                                {message.contenu}
                                                                            </h6>
                                                                        </div>
                                                                        {/*<div className="col-6 text-end"><span className="card-briefcase">New York, US</span></div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="ModalDonMessageForm" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content apply-job-form">
                                <button className="btn-close" type="button"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="modal-body pl-30 pr-30 pt-50">
                                    <div className="text-center">
                                        <p className="font-sm text-brand-2">Formulaire Message </p>
                                    </div>
                                    <form className="login-register text-start mt-20 pb-30"
                                          action="#">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="input-1">Message *</label>
                                            <textarea
                                                style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                                className="form-control"
                                                placeholder="Message"
                                                id="contenu"
                                                name="contenu"
                                                rows="10"
                                                onChange={handleAddMessage}
                                                value={messageData.contenu}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button onClick={submitMessage} className="btn btn-default hover-up w-100"
                                                    type="submit" name="login">Envoyé
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default DetailAnnonce;