import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import './App.css';

//import Context
import DispatchContext from "./Context/DispatchContext";
import StateContext from "./Context/StateContext";
import Home from "./components/Home";
import Index from "./components/Index";
import Inscription from "./components/Inscription";
import Connexion from "./components/Connexion";
import AddAnnonce from "./components/AddAnnonce";
import Annonces from "./components/Annonces";
import DetailAnnonce from "./components/DetailAnnonce";
import About from "./components/About";
import Dashboard from "./components/Dashboard";
import PaiementSuccess from "./components/PaimentSuccess";
import Notification from "./components/Notification";
import Pricing from "./components/Pricing";
import AddAnnonnceGratuite from "./components/AddAnnonceInit";
import AddAnnonncePremium from "./components/AddAnnoncePremium";
import AddAnnonncePrivilege from "./components/AddAnnoncePrivilege";
function App() {

  const initialState = {
    userUsername: localStorage.getItem('theuserUsername'),
    userEmail: localStorage.getItem('theuserEmail'),
    userId : localStorage.getItem('theUserId'),
    userToken: localStorage.getItem('theUserToken'),
    userIsConnecter:localStorage.getItem('theuserUsername') ? true : false,
  }

  function ReducerFunction (draft, action) {
    switch(action.type) {
      case 'catchToken':
          draft.userToken = action.tokenValue;
      break;
      case 'userConnecter':
          draft.userUsername = action.usernameInfo;
          draft.userEmail = action.emailInfo;
          draft.userIsConnecter = true;
          draft.userId = action.IdInfo;
      break;

      case 'logout':
          draft.userIsConnecter = false
      break
    }
  }
  const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)
  useEffect(()=> {
      if(state.userIsConnecter) {
          localStorage.setItem('theuserUsername', state.userUsername)
          localStorage.setItem('theuserEmail', state.userEmail)
          localStorage.setItem('theUserId', state.userId)
          localStorage.setItem('theUserToken', state.userToken)
      } else {
         localStorage.removeItem('theuserUsername')
         localStorage.removeItem('theuserEmail')
         localStorage.removeItem('theUserId')
         localStorage.removeItem('theUserToken')
      }
    }, [state.userIsConnecter]);

  return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/connexion" element={<Connexion />} />
                    <Route path="/inscription" element={<Inscription />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/add_annonce" element={<AddAnnonce />} />
                    <Route path="/annonce_gratuite" element={<AddAnnonnceGratuite />} />
                    <Route path="/annonce_premium" element={<AddAnnonncePremium />} />
                    <Route path="/annonce_privilege" element={<AddAnnonncePrivilege />} />
                    <Route path="/annonces" element={<Annonces />} />
                    <Route path="/contribution" element={<PaiementSuccess />} />
                    <Route path="/notify" element={<Notification />} />
                    <Route path="/annonces/:id" element={<DetailAnnonce />} />
                </Routes>
            </BrowserRouter>
        </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
