import React, {Fragment} from "react";
import Frame from 'react-frame-component';
import Logo from "./assets/images/logobleu.png";

function PaiementSuccess() {
    const initialContent = `
    <!DOCTYPE html>
        <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport"
                      content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
                <meta http-equiv="X-UA-Compatible" content="ie=edge">
                <link rel="stylesheet" href="../components/html/css/fontawesome.css">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
                <title>CINETPAY TEST</title>
                <script src="https://cdn.cinetpay.com/seamless/main.js" type="text/javascript"></script>
                <script src="../components/html/js/all.js" type="text/javascript"></script>
                <style>
                    .sdk {
                        display: block;
                        position: absolute;
                        background-position: center;
                        text-align: center;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                </style>
                <script>
                    function checkout() {
                        CinetPay.setConfig({
                            // notify_url: 'http://5.196.67.164:3000/notify/',
                            mode: 'PRODUCTION',
                            apikey: '122849245365f9bc10243b46.95821228',//   YOUR APIKEY
                            site_id: '5866540',//YOUR_SITE_ID
                            notify_url: 'http://koliac.tech/notify/',
                            close_after_response: true,
                        });
                        CinetPay.getCheckout({
                            transaction_id: Math.floor(Math.random() * 100000000).toString(), // YOUR TRANSACTION ID
                            amount: 100,
                            currency: 'XOF',
                            channels: 'ALL',
                            description: 'Test de paiement',
                             //Fournir ces variables pour le paiements par carte bancaire
                            // customer_name:"Joe",//Le nom du client
                            // customer_surname:"Down",//Le prenom du client
                            // customer_email: "down@test.com",//l'email du client
                            // customer_phone_number: "088767611",//l'email du client
                            // customer_address : "BP 0024",//addresse du client
                            // customer_city: "Antananarivo",// La ville du client
                            // customer_country : "CI",// le code ISO du pays
                            // customer_state : "CI",// le code ISO l'état
                            // customer_zip_code : "06510", // code postal
            
                        });
                        CinetPay.waitResponse(function(data) {
                            if (data.status == "REFUSED") {
                                if (alert("Votre paiement a échoué, SOlde Insuffisant !!")) {
                                    window.location.reload();
                                }
                            } else if (data.status == "ACCEPTED") {
                                if (alert("Votre paiement a été effectué avec succès")) {
                                    window.location.reload();
                                }
                            }
                        });
                        CinetPay.onError(function(data) {
                            console.log(data);
                        });
                    }
                </script>
            </head>
            <body class="container bg-gray-dark col-md-8 offset-2">
                <div class="card text-center mt-50">
                  <div class="card-header">
                    Contribution pour l'annonce 
                  </div>
                  <div class="card-body mt-10" style="background-color: #595454">
<!--                    <img src="assets/images/don.png" class="card-img mb-3" alt="..." style="height: 300px; border-radius: 20px">-->
                    <i class="fa-duotone fa-circle-dollar-to-slot"></i>
                    <h5 class="card-title text-white">CONTRIBUER</h5> 
                    <p class="card-text text-white">Soutenir un Proche dans les Moments Douloureux</p>
                    <button
                        onclick="checkout()"
                        class="btn btn-primary"
                        style="
                            border-radius: 30px;
                            font-weight: bold; 
                            font-size: 18px; 
                            padding: 35px; 
                            text-transform: uppercase;
                            border: 10px solid #f1eaea;
                            boxShadow: #f1eaea 1px 1px 1px,
                        "
                    >
                        Initialiser Le Paiement
                    </button>
                  </div>
                  <div class="card-footer text-muted">
                    Koliac Ensemble Avec Vous !
                  </div>
                </div>
                <!--<div class="col-sm-6 offset-3">
                    <div class="sdk">
                        <h1>CONTRIBUER</h1>
                        <button 
                             class="btn btn-primary font-bold text-uppercase"
                             style="
                <!--                border-radius: 30px;-->
                <!--                font-weight: bold; -->
                <!--                font-size: 16px; -->
                <!--                padding: 20px; -->
                <!--                text-transform: uppercase;-->
                <!--                border: "10px solid white";-->
                <!--                boxShadow: "grey 1px 1px 1px",-->
                <!--             "-->
                <!--             onclick="checkout()"-->
                <!--        >-->
                <!--            Cliquer Pour initialiser Le Paiement-->
                <!--        </button>-->
                <!--    </div>-->
                <!--</div>-->
            </body>
        </html>`;

  return (
      <Fragment>
          <div className="col-md-6 offset-3">
              <div className="">
                  <img className="col-md-4 offset-4 mt-50 mb-0" src={Logo} width={300} height={180}
                       style={{borderRadius: 50}} alt=""/>
              </div>
              <Frame
                  initialContent={initialContent}
                  width="100%"
                  height="950px"
                  frameBorder="0"
                  allowFullScreen
                  target="_parent"
              >
              </Frame>
          </div>
      </Fragment>
  )

}

export default PaiementSuccess;