import React, {Fragment} from "react";
// import Header from "./_partials/Header";
import HeaderIndex from "./_partials/HeaderIndex";
import BannerImg1 from "./assets/images/Koliac_resized.png"
// import BannerImg2 from "./assets/images/image2.png"
// import BannerImg3 from "./assets/images/image3.png"
// import BannerImg4 from "./assets/images/screan5.png"
// import BannerImg5 from "./assets/images/screa6n.png"
// import BannerImg6 from "./assets/images/screan7.png"

function Index() {
    return (
        <Fragment>
            <body>
                <div id="preloader-active">
                  <div className="preloader d-flex align-items-center justify-content-center">
                    <div className="preloader-inner position-relative">
                      <div className="text-center"><img src="assets/imgs/template/loading.gif" alt="jobBox" /></div>
                    </div>
                  </div>
                </div>
                <HeaderIndex />
                <main className="main">
                  <section className="section-box mb-70">
                    <div className="banner-hero hero-1 banner-homepage5">
                      <div className="banner-inner">
                        <div className="row">
                          <div className="col-xl-7 col-lg-12">
                            <div className="block-banner">
                              <h1 className="heading-banner wow animate__animated animate__fadeInUp">Bienvenue sur Yaako,<br className="d-none d-lg-block" /> Vous ne serez jamais Seuls</h1>
                              <div className="banner-description mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Soutenir un Proche dans les moments Douloureux .</div>
                              <div className="mt-30">
                                {/*<a className="btn btn-default mr-15">Démarrer</a>*/}
                                  <a href="/home" className="btn btn-default mr-15">Démarrer</a>
                                {/*<a className="btn btn-border-brand-2">Learn more</a>*/}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-5 col-lg-12 d-none d-xl-block col-md-6">
                            <div className="banner-imgs">
                              <div className="banner-1 shape-1"><img className="img-responsive" alt="jobBox" src={BannerImg1} style={{borderRadius: 30, width: 500}} /></div>
                              {/* <div className="banner-2 shape-2"><img className="img-responsive" alt="jobBox" src={BannerImg2} /></div>
                              <div className="banner-3 shape-3"><img className="img-responsive" alt="jobBox" src={BannerImg3} /></div>
                              <div className="banner-4 shape-3"><img className="img-responsive" alt="jobBox" src={BannerImg4} width={180} height={180} /></div>
                              <div className="banner-5 shape-2"><img className="img-responsive" alt="jobBox" src={BannerImg5} width={150} height={150} /></div>
                              <div className="banner-6 shape-1"><img className="img-responsive" alt="jobBox" src={BannerImg6} style={{borderRadius: 500, width: 350, height: 150}} /></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <script src="assets/js/plugins/counterup.js"></script>
                </main>
            </body>
        </Fragment>
    )
}

export default Index;