import React, {Fragment} from 'react'

function Pagination({totalAnnonces, annoncesPerPage, setCurrentPage, currentPage}) {
    let pages = [];

    for (let i = 1; i <= Math.ceil(totalAnnonces / annoncesPerPage); i++) {
        pages.push(i);
    }

    return (
        <Fragment>
            <div className="paginations text-center">
                <ul className="pager">
                    {pages.map((page, index) => {
                        return (
                            <li key={index}>
                                <a
                                    className={page === currentPage ? "active pager-number" : ""}
                                    onClick={() => setCurrentPage(page)}
                                    style={{cursor: "pointer"}}
                                >
                                    {page}
                                </a>
                            </li>
                            // <button
                            //   key={index}
                            //   className={page === currentPage ? "pager-number" : ""}
                            //   onClick={() => setCurrentPage(page)}
                            // >
                            // {page}
                            // </button>
                        );
                    })}
                </ul>
            </div>
        </Fragment>
    );

}
export default Pagination;